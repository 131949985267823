import { useHydrateAtoms } from "jotai/utils";

import {
	userLoggedInAtom,
	userRolesAtom
} from "_common/hooks/auth";
import {
	darkModeAtom,
	deviceTypeAtom,
	isWebviewAtom,
	userCountryAtom
} from "_common/hooks/global";

// This is added since hydration was keeping same value on the server
// causing wrong values on client
const HydrateAtoms = props => {
	useHydrateAtoms ( [
		[ userLoggedInAtom, props?.userAuth?.userId ? true : false ],
		[ deviceTypeAtom, props.isMobile ? "mobile" : "desktop" ],
		[ darkModeAtom, props?.darkMode ? true : false ],
		[ userRolesAtom, props?.userAuth?.userRoles || [] ],
		[ userCountryAtom, props?.viewerCountry ],
		[ isWebviewAtom, props?.isWebview ]
	],
	{
		dangerouslyForceHydrate: true
	}
	);

	return null;
};

export default HydrateAtoms;
